import { useState, useEffect, useRef } from "react";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import Language from "@components/Language";

import "@stylesComponents/Header.scss";

import Logo from "@images/logo.svg";

export default function Header() {
  const { t } = useI18next("header");
  const [active, setActive] = useState(false);
  const [showScroll, setShowScroll] = useState(false);
  const toTopRef = useRef(null);
  const headerRef = useRef(null);
  const media = typeof window !== "undefined" && matchMedia("(max-width: 900px)");

  const handleMenu = () => {
    setActive(!active);
  };

  const handleScrollTop = () => {
    toTopRef.current?.scrollIntoView({
      behavior: "smooth"
    });
  };

  useEffect(() => {
    const html = document.querySelector("html");
    const body = document.querySelector("body");
    const listener = () => {
      if (media?.matches) {
        setActive(!media?.matches);
      }
      if (active && media?.matches) {
        html.style.overflow = "hidden";
        body.style.overflow = "hidden";
      } else {
        html.style.overflow = "initial";
        body.style.overflow = "initial";
      }
    };
    media?.addListener(listener);

    if (active && media?.matches) {
      html.style.overflow = "hidden";
      body.style.overflow = "hidden";
    } else {
      html.style.overflow = "initial";
      body.style.overflow = "initial";
    }
    return () => {
      media?.removeListener(listener);
    };
  }, [active, media]);

  useEffect(() => {
    const toTopCurrent = toTopRef.current;
    const headerCurrent = headerRef.current;
    const observer = new IntersectionObserver((entries) => {
      const toTop = entries[0];

      if (toTop.isIntersecting) {
        setShowScroll(false);
        headerCurrent.classList.remove("header--fixed");
      } else {
        headerCurrent.classList.add("header--fixed");
        setShowScroll(true);
      }
    });
    if (toTopCurrent && headerCurrent) {
      observer.observe(toTopCurrent);
    }
    return () => {
      if (toTopCurrent) {
        observer.unobserve(toTopCurrent);
      }
    };
  }, [toTopRef, headerRef]);

  return (
    <>
      <span className="totop" ref={toTopRef} />
      <header className="header" ref={headerRef}>
        <nav className="header__nav">
          <Link to="/" className="header__logo-container">
            <img src={Logo} alt="FxBravo" className="header__logo" />
          </Link>
          <button
            onClick={handleMenu}
            type="button"
            className="header__menu-button"
          >
            <div className="header__menu-button-icon" data-active={active}>
              <span />
              <span />
              <span />
            </div>
          </button>
          <ul data-active={active} className="header__list">
            <li className="header__list-item">
              <Link
                to="/about"
                className="header__link"
                activeClassName="header__link--active"
              >
                {t("about")}
              </Link>
            </li>
            <li className="header__list-item">
              <Link
                to="/platform"
                className="header__link"
                activeClassName="header__link--active"
              >
                {t("platform")}
              </Link>
            </li>
            {/* <li className="header__list-item">
              <Link to="/market" className="header__link" activeClassName="header__link--active">
                {t("market")}
              </Link>
            </li> */}
            <li className="header__list-item">
              <Link
                to="/contact"
                className="header__link"
                activeClassName="header__link--active"
              >
                {t("contact")}
              </Link>
            </li>
            <li className="header__list-item">
              <a
                href="https://clientzone.fxbravo.net/login"
                className="header__sign"
              >
                {t("signin")}
              </a>
            </li>
            <li className="header__list-item">
              <a
                href="https://clientzone.fxbravo.net/register"
                className="header__sign header__sign--up"
              >
                {t("signup")}
              </a>
            </li>
            <li className="header__list-item">
              <Language />
            </li>
          </ul>
        </nav>
      </header>
      {showScroll && (
        <button type="button" className="scrolltop" onClick={handleScrollTop} />
      )}
    </>
  );
}
