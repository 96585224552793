import { Link, useI18next } from "gatsby-plugin-react-i18next";
import "@stylesComponents/Footer.scss";
import Logo from "@images/logo.svg";
import Banner from "@components/Banner";
import { useEffect, useState } from "react";

export default function Footer() {
  const { t } = useI18next();
  const [location, setLocation] = useState("");
  const [country, setCountry] = useState("");

  const getLocation = () => {
    fetch("https://api.ipregistry.co/?key=ccu1zlzhgetyu5")
      .then((response) => response.json())
      .then((data) => {
        setLocation(data.location.continent.code);
        setCountry(data.location.country.code);
      });
  };

  useEffect(() => {
    getLocation();
  }, []);
  return (
    <>
      <Banner />
      <footer className="footer">
        <div className="footer__content">
          <Link to="/" className="footer__logo-container">
            <img src={Logo} alt="Logo" className="footer__logo" />
          </Link>
          <p className="footer__copy-right">
            {t("terms")}{" "}
            {(location === "EU" || country === "AR") && (
              <span>{t("terms3")} </span>
            )}
            {t("terms2")}
          </p>
          <ul className="footer__list">
            <li className="footer__list-item">
              <Link to="/about" className="footer__link">
                {t("about")}
              </Link>
            </li>
            <li className="footer__list-item">
              <Link to="/platform" className="footer__link">
                {t("platform")}
              </Link>
            </li>
            {/* <li className="footer__list-item">
              <Link to="/market" className="footer__link">
                {t("market")}
              </Link>
            </li> */}
            <li className="footer__list-item">
              <Link to="/contact" className="footer__link">
                {t("contact")}
              </Link>
            </li>
            <li className="footer__list-item">
              <Link to="/terms" className="footer__link">
                {t("legals")}
              </Link>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
}
