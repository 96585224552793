import { useState } from "react";
import { useI18next } from "gatsby-plugin-react-i18next";

import "@stylesComponents/Banner.scss";

import IconBanner from "@icons/icon-banner-chat.svg";

export default function Banner() {
  const [hidden, setHidden] = useState(typeof document !== "undefined" ? document.cookie.includes("banner-hidden") : false);
  const { t } = useI18next("banner");

  const handleClose = () => {
    setHidden(true);
    document.cookie = "banner-hidden=true";
  };

  if (hidden) {
    return null;
  }

  return (
    <div className="banner">
      <div className="banner__content">
        <button type="button" onClick={handleClose} className="banner__close" />
        <p className="banner__text">
          {t("text")}
        </p>
        <figure className="banner__icon-container">
          {/* <img src={IconBanner} alt="" className="banner__icon" /> */}
        </figure>
      </div>
    </div>
  );
}
